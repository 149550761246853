.dropdown_container {
  all: unset;
  width: '100%';
  max-width: '100%';
  position: relative;
  /* flex: 1; */
}

.dropdown_button {
  /* all: unset !important; */
  max-width: 100%;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left;
  color: #0a4475 !important;
  min-height: 40px;
  font-family: 'Manrope', sans-serif;
  text-decoration: none;
  line-height: 1.3;
  /* padding: 12px 30px; */
  font-weight: 400;
  font-size: 16px;
  position: relative !important;
  box-shadow: inset 1px 3px 5px 0px #0000001c;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 40px;
}

.dropdown_button:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 20%;
  height: 100%;
  width: 50%;
  opacity: 0;
  -webkit-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjIyLjY1cHgiIGhlaWdodD0iMjcwLjA1NnB4IiB2aWV3Qm94PSIwIDAgMjIyLjY1IDI3MC4wNTYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIyMi42NSAyNzAuMDU2Ig0KCSB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik05NC4yNCwyNjkuMjU1SDIuMzczbDEyNi42NDgtMTM0LjQ1M0w2Ny42MzksMC41MjdoOTEuODk3bDYxLjM2NiwxMzQuMjc1TDk0LjI0LDI2OS4yNTV6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
}

.dropdown_menu {
  /* all: unset; */
  position: absolute !important;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  z-index: 9999;
  max-height: 280px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  top: 160%;
  left: 0;
  overflow: hidden;
}

.dropdown_list {
  display: none;
  position: absolute;
  z-index: 1;
  background: #f2f2f2;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdown_button:after {
  content: ' ';
  position: absolute;
  top: calc(50% - 9px);
  right: 15px;
  display: block;
  width: 18px;
  height: 18px;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTRweCIgaGVpZ2h0PSI4cHgiIHZpZXdCb3g9IjAgMCAxNCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxNCA4IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiMwQTQ0NzUiIGQ9Ik0wLjYzNiwwLjI5M0MwLjgyNCwwLjEwNSwxLjA3OCwwLDEuMzQzLDBTMS44NjIsMC4xMDUsMi4wNSwwLjI5M0w3LDUuMjQzbDQuOTUtNC45NQ0KCWMwLjE4OS0wLjE4MiwwLjQ0MS0wLjI4MywwLjcwMy0wLjI4MWMwLjI2MiwwLjAwMiwwLjUxMywwLjEwNywwLjY5OCwwLjI5M3MwLjI5MSwwLjQzNiwwLjI5MywwLjY5OHMtMC4wOTksMC41MTUtMC4yODEsMC43MDMNCglMNy43MDcsNy4zNjRDNy41MTksNy41NTEsNy4yNjUsNy42NTcsNyw3LjY1N1M2LjQ4MSw3LjU1Miw2LjI5Myw3LjM2NEwwLjYzNiwxLjcwN0MwLjQ0OSwxLjUxOSwwLjM0MywxLjI2NSwwLjM0MywxDQoJUzAuNDQ5LDAuNDgsMC42MzYsMC4yOTN6Ii8+DQo8L3N2Zz4NCg==');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: transform 0.3s ease;
  transform: rotate(0deg);
}

.dropdown_button.active::after {
  transform: rotate(180deg);
}

.dropdown_content {
  max-height: 200px;
  flex: 1;
  overflow-y: auto;
  padding-left: 0;
  z-index: 999;
}

.dropdown_item {
  padding: 8px 12px;
  border-bottom: 1px solid #eee;
}

.dropdown_item:last-child {
  border-bottom: none;
}

.dropdown_item label {
  display: flex;
  align-items: center;
}

.btnBlueOutline {
  font-size: 16px;
  padding: 8px 15px;
  text-transform: none;
  margin-top: 10px;
  margin-bottom: 10px;
  background: none;
  border-radius: 5px;
  color: #0a4475 !important;
  border: 1px solid #0a4475;
  margin-left: 10px;
  margin-right: auto;
}

.btn_input {
  font-weight: 400;
  text-transform: none;
  color: #012140 !important;
  background-color: #fff;
  border-top: 1px solid #ffffffa3;
  border-bottom: 1px solid #01214024;
  border-left: 1px solid #01214024;
  border-right: 1px solid #01214024;
  box-shadow: inset 1px 3px 5px 0px #0000001c;
  max-width: 100% !important;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.body {
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  /* line-height: 1.3;
    padding: 0;
    margin: 0; */
  font-weight: 400;
  color: #012140;
}

.btn {
  font-family: 'Manrope', sans-serif;
  background-color: #b60e27;
  color: #fff !important;
  margin: 0;
  text-align: center;
  font-size: 18px;
  line-height: 1.3;
  text-transform: uppercase;
  padding: 12px 30px;
  font-weight: 800;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid #b60e27;
  border-radius: 5px;
  user-select: none;
  position: relative;
}

.btn:hover {
  background: #9a031a;
  border-color: #9a031a;
  color: #fff !important;
  /*transform:scale(1.05);*/
}

.btn:hover::before {
  opacity: 0.2;
  left: 44%;
}

.btnSmall {
  font-size: 16px !important;
  padding: 8px 15px !important;
  text-transform: none;
  margin: 0;
}

.btnDropdown {
  padding-right: 55px !important;
  padding-left: 15px !important;
  display: block;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
}

.btnDropdownActive:after {
  transform: rotate(180deg);
}

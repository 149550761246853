.filterForm {
  -webkit-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
}

@media (max-width: 992px) {
  .filterReports {
    margin-top: 50px;
    background: #0a447521;
    padding: 10px 20px 4px 20px;
    border-radius: 5px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.05);
    margin-bottom: -50px;
  }
}

.small,
small {
  font-size: 16px;
}

.small .input + label:after,
.btnSmall .input + label:after,
.small .input + label:before,
.btnSmall .input + label:before {
  top: 0px;
}

@media (max-width: 992px) {
  .filterForm {
    display: none;
    -webkit-transition: 0s;
    -o-transition: 0s;
    transition: 0s;
    background: #fff;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  }

  .filterForm.opened {
    display: block;
  }

  .small,
  small {
    font-size: 14px;
  }

  .small .input + label:after,
  .btnSmall .input + label:after,
  .small .input + label:before,
  .btnSmall .input + label:before {
    top: -1px;
  }

  .btn {
    font-size: 16px;
    padding: 12px 30px;
  }

  .btnSmall {
    font-size: 14px !important;
    padding: 8px 13px !important;
  }
}

@media (max-width: 575px) {
  table.tableFilterPanel,
  table.tableFilterPanel tbody,
  table.tableFilterPanel tr,
  table.tableFilterPanel td {
    display: block;
    padding: 0;
  }
  table.tableFilterPanel td {
    padding-bottom: 15px;
  }
  table.tableFilterPanel td.filterCellStatus {
    width: 48%;
    display: inline-block;
    float: left;
  }
  table.tableFilterPanel td.filterCellAdd {
    width: 48%;
    display: inline-block;
    float: right;
  }

  table.tableFilterPanel {
    margin: 0 0 0px 0;
  }

  .menu a:not(.btn) {
    padding: 5px;
  }

  .tableFilterPanel input[type='date'] {
    font-size: 14px;
    height: 45px;
  }

  .table-big-wrapper {
    margin: 0 0 10px 0;
  }
  .filterDocs .tableFilterPanel {
    margin-bottom: 0 !important;
  }
}

.dropdown {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.dropdown .dropdownContent {
  position: absolute;
  z-index: 888;
  top: calc(100% + 5px);
  left: 0;
  display: none;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 4px 10px rgb(0 0 0 / 30%);
  min-width: 100%;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  user-select: none;
}
.scrollDropdown {
  padding-top: 0 !important;
  max-height: 300px;
  overflow-y: scroll;
}

.dropdown.dropdownMultipleSelect .btnDropdown {
  white-space: nowrap;
}

.dropdown.dropdownMultipleSelect .dropdownContent > * {
  padding: 7px 15px;
  display: block;
  border-bottom: 1px solid #01214024;
}

.dropdownInputRole {
  padding: 8px 0;
  display: block;
  border-bottom: 1px solid #01214024;
}
.dropdownInput {
  padding: 7px 15px;
  display: block;
  border-bottom: 1px solid #01214024;
}

.dropdownInput:hover {
  color: #b60e27;
}

.dropdown.dropdownMultipleSelect .dropdown-content > *:hover {
  color: #b60e27;
}

.dropdown.dropdownMultipleSelect .dropdown-content label {
  display: block;
}

.dropdown.dropdownMultipleSelect .dropdownContent label {
  display: block;
}

.dropdown.opened .dropdownContent {
  display: block;
}

.dropdown.opened .btnDropdown:after {
  transform: rotate(180deg);
}

.btn {
  font-size: 16px;
  padding: 12px 30px;
}

.btn,
button {
  font-family: 'Manrope', sans-serif;
  background-color: #b60e27;
  color: #fff !important;
  text-align: center;
  font-size: 18px;
  line-height: 1.3;
  text-transform: uppercase;
  padding: 12px 30px;
  font-weight: 800;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid #b60e27;
  border-radius: 5px;
  user-select: none;
  position: relative;
}

.btn:hover {
  background: #9a031a;
  border-color: #9a031a;
  color: #fff !important;
  /*transform:scale(1.05);*/
}

.btn:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 20%;
  height: 100%;
  width: 50%;
  opacity: 0;
  -webkit-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjIyLjY1cHgiIGhlaWdodD0iMjcwLjA1NnB4IiB2aWV3Qm94PSIwIDAgMjIyLjY1IDI3MC4wNTYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIyMi42NSAyNzAuMDU2Ig0KCSB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik05NC4yNCwyNjkuMjU1SDIuMzczbDEyNi42NDgtMTM0LjQ1M0w2Ny42MzksMC41MjdoOTEuODk3bDYxLjM2NiwxMzQuMjc1TDk0LjI0LDI2OS4yNTV6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
}

.btn:hover::before {
  opacity: 0.2;
  left: 44%;
}

.btnSmall {
  font-size: 16px !important;
  padding: 6px 15px !important;
  text-transform: none;
  margin: 0;
}

.btnWide {
  display: block;
  width: 100%;
}

.btnInput {
  font-weight: 400;
  text-transform: none;
  color: #012140 !important;
  background-color: #fff;
  border-top: 1px solid #ffffffa3;
  border-bottom: 1px solid #01214024;
  border-left: 1px solid #01214024;
  border-right: 1px solid #01214024;
  box-shadow: inset 1px 3px 5px 0px #0000001c;
  max-width: 100% !important;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.btnInput:hover {
  background: #0a4475;
  border-color: #0a4475;
  color: #fff !important;
}

.dropdown > .btnDropdown:hover:after,
.dropdown > .btnDropdown.btn-blue:after {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjYzNjA0OCAwLjI5Mjc4N0MwLjgyMzU3NiAwLjEwNTMxNiAxLjA3Nzg4IDAgMS4zNDMwNSAwQzEuNjA4MjEgMCAxLjg2MjUyIDAuMTA1MzE2IDIuMDUwMDUgMC4yOTI3ODdMNy4wMDAwNSA1LjI0Mjc5TDExLjk1IDAuMjkyNzg3QzEyLjEzODcgMC4xMTA2MjkgMTIuMzkxMyAwLjAwOTgzMzcyIDEyLjY1MzUgMC4wMTIxMTIxQzEyLjkxNTYgMC4wMTQzOTA2IDEzLjE2NjUgMC4xMTk1NiAxMy4zNTE5IDAuMzA0OTY4QzEzLjUzNzMgMC40OTAzNzYgMTMuNjQyNCAwLjc0MTE4OCAxMy42NDQ3IDEuMDAzMzhDMTMuNjQ3IDEuMjY1NTggMTMuNTQ2MiAxLjUxODE4IDEzLjM2NCAxLjcwNjc5TDcuNzA3MDUgNy4zNjM3OUM3LjUxOTUyIDcuNTUxMjYgNy4yNjUyMSA3LjY1NjU3IDcuMDAwMDUgNy42NTY1N0M2LjczNDg4IDcuNjU2NTcgNi40ODA1OCA3LjU1MTI2IDYuMjkzMDUgNy4zNjM3OUwwLjYzNjA0OCAxLjcwNjc5QzAuNDQ4NTc3IDEuNTE5MjYgMC4zNDMyNjIgMS4yNjQ5NSAwLjM0MzI2MiAwLjk5OTc4NkMwLjM0MzI2MiAwLjczNDYyMiAwLjQ0ODU3NyAwLjQ4MDMxNCAwLjYzNjA0OCAwLjI5Mjc4N1oiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=');
}

.dropdown > .btnDropdown {
  padding-right: 45px !important;
  padding-left: 15px !important;
  display: block;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dropdown > .btnDropdown:after {
  content: ' ';
  position: absolute;
  top: calc(50% - 9px);
  right: 15px;
  display: block;
  width: 18px;
  height: 18px;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTRweCIgaGVpZ2h0PSI4cHgiIHZpZXdCb3g9IjAgMCAxNCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxNCA4IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiMwQTQ0NzUiIGQ9Ik0wLjYzNiwwLjI5M0MwLjgyNCwwLjEwNSwxLjA3OCwwLDEuMzQzLDBTMS44NjIsMC4xMDUsMi4wNSwwLjI5M0w3LDUuMjQzbDQuOTUtNC45NQ0KCWMwLjE4OS0wLjE4MiwwLjQ0MS0wLjI4MywwLjcwMy0wLjI4MWMwLjI2MiwwLjAwMiwwLjUxMywwLjEwNywwLjY5OCwwLjI5M3MwLjI5MSwwLjQzNiwwLjI5MywwLjY5OHMtMC4wOTksMC41MTUtMC4yODEsMC43MDMNCglMNy43MDcsNy4zNjRDNy41MTksNy41NTEsNy4yNjUsNy42NTcsNyw3LjY1N1M2LjQ4MSw3LjU1Miw2LjI5Myw3LjM2NEwwLjYzNiwxLjcwN0MwLjQ0OSwxLjUxOSwwLjM0MywxLjI2NSwwLjM0MywxDQoJUzAuNDQ5LDAuNDgsMC42MzYsMC4yOTN6Ii8+DQo8L3N2Zz4NCg==');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.btnSmall2 {
  font-size: 14px !important;
  padding: 7px 13px !important;
  text-transform: none;
}

.btnBlueOutline {
  background: none;
  color: #0a4475 !important;
  border: 1px solid #0a4475;
}

.btnBlueOutline:hover {
  background: #0a4475;
  border-color: #0a4475;
  color: #fff !important;
}

.icon {
  display: inline-block;
  vertical-align: middle;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
}

.iconSearchWhite {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjFweCIgaGVpZ2h0PSIyMXB4IiB2aWV3Qm94PSIwIDAgMjEgMjEiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIxIDIxIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iI0ZGRkZGRiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIGQ9Ik0yMCwyMGwtNC40ODYtNC40OTQgTTE4LDkuNQ0KCWMwLDIuMjU0LTAuODk2LDQuNDE2LTIuNDksNi4wMVMxMS43NTQsMTgsOS41LDE4cy00LjQxNi0wLjg5Ni02LjAxLTIuNDlTMSwxMS43NTQsMSw5LjVzMC44OTYtNC40MTYsMi40OS02LjAxUzcuMjQ2LDEsOS41LDENCglzNC40MTYsMC44OTYsNi4wMSwyLjQ5UzE4LDcuMjQ2LDE4LDkuNUwxOCw5LjV6Ii8+DQo8L3N2Zz4NCg==');
}

.input {
  position: absolute;
  opacity: 0;
  user-select: none;
}

.label {
  position: relative;
  padding: 0 0 0 30px;
  margin-right: 0px;
  display: inline-block;
  cursor: pointer;
  user-select: none;
}

.input + label:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
  border: 1px solid #cdd1da;
  border-radius: 4px;
  background: #fff;
  position: absolute;
  top: 2px;
  left: 0;
}

.input:checked + label:before {
  background-color: #b60e27;
  border-color: #b60e27;
}

.input + label:after {
  content: ' ';
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDE2IDE2IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxyZWN0IGZpbGw9IiNCNjBFMjciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIvPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTEyLjYzLDUuNzA5YzAuMDc3LTAuMDg4LDAuMTM2LTAuMTkxLDAuMTczLTAuMzAzYzAuMDM3LTAuMTEyLDAuMDUxLTAuMjI5LDAuMDQyLTAuMzQ2DQoJcy0wLjA0Mi0wLjIzMS0wLjA5Ni0wLjMzNmMtMC4wNTQtMC4xMDQtMC4xMjgtMC4xOTctMC4yMTktMC4yNzJjLTAuMDktMC4wNzUtMC4xOTUtMC4xMzItMC4zMDctMC4xNjZzLTAuMjMtMC4wNDYtMC4zNDctMC4wMzQNCgljLTAuMTE3LDAuMDEyLTAuMjMsMC4wNDctMC4zMzMsMC4xMDRjLTAuMTAzLDAuMDU2LTAuMTk0LDAuMTMzLTAuMjY3LDAuMjI1TDcuNDg4LDkuMTI3bC0xLjk2LTEuOTYxDQoJYy0wLjE2Ni0wLjE2LTAuMzg5LTAuMjQ5LTAuNjItMC4yNDdDNC42NzcsNi45Miw0LjQ1Niw3LjAxMyw0LjI5Myw3LjE3NkM0LjEyOSw3LjM0LDQuMDM3LDcuNTYsNC4wMzUsNy43OTENCgljLTAuMDAyLDAuMjMxLDAuMDg3LDAuNDU0LDAuMjQ3LDAuNjJsMi42NDMsMi42NDNjMC4wODcsMC4wODcsMC4xOSwwLjE1NCwwLjMwNCwwLjE5OHMwLjIzNiwwLjA2NCwwLjM1OCwwLjA1OQ0KCWMwLjEyMi0wLjAwNiwwLjI0Mi0wLjAzNiwwLjM1Mi0wLjA5MWMwLjExLTAuMDU0LDAuMjA3LTAuMTMxLDAuMjg1LTAuMjI1TDEyLjYzLDUuNzA5eiIvPg0KPC9zdmc+DQo=');
  background-position: center center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  background-size: contain;
  position: absolute;
  left: 0px;
  top: 2px;
  opacity: 0;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.small .input + label:after,
.btnSmall .input + label:after,
.small .input + label:before,
.btnSmall .input + label:before {
  top: 0px;
}

.input:checked + label:after {
  opacity: 1 !important;
}

@media (max-width: 1023px) {
  .input + label:before {
    top: 1px;
  }

  .small .input + label:after,
  .btn-small .input + label:after,
  .small .input + label:before,
  .btn-small .input + label:before {
    top: -1px;
  }
}

.roles {
  margin-left: 30px;
}

.tableFilterPanel {
  margin-left: -5px;
  margin-right: -5px;
}
.tableFilterPanel > div {
  padding: 0px 5px 6px 5px;
}

.tableFilterPanel .dropdown {
  display: block;
}
.tableFilterPanel:after {
  content: ' ';
  width: 100%;
  display: block;
  clear: both;
}

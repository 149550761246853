.accountContainer {
  position: relative;
  z-index: 1;
  padding: 100px 0;
  margin: 0 auto;
  text-align: center;
}

.accountWideContent {
  display: block;
  width: calc(100% - 160px);
  vertical-align: top;
  margin-left: 160px;
  position: relative;
  z-index: 5;
}

.accountWideContent:before {
  content: ' ';
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  background: #f5f7f8;
  width: 100%;
  height: 100%;
}

.accountWideContentContainer {
  padding: 0 50px;
  position: relative;
  z-index: 5;
  max-width: 1600px;
  margin: 0 auto;
}

.fontBig {
  font-size: 40px;
  letter-spacing: -0.03em;
  font-family: 'Manrope', sans-serif;
  font-weight: 800;
  margin: 20px 0 40px 0;
}

.h2 {
  font-size: 80px !important;
}

.message {
  font-size: 30px;
  padding: 0 30px;
}

.truckAnimation {
  height: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  text-align: center;
}

.button {
  font-size: 18px;
  padding: 10px 20px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #0fbaba;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
}

@media (max-width: 1023px) {
  .accountWideContent {
    display: block;
    width: 100%;
    margin: 0px !important;
  }

  .accountWideContentContainer {
    padding: 20px 15px;
  }

  .accountContainer {
    padding: 45px 0 0 0px;
  }

  .btn {
    font-size: 16px;
    padding: 12px 30px;
  }

  .btnSmall {
    font-size: 14px;
    padding: 8px 13px;
  }
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Высота контейнера может быть изменена в соответствии с вашими потребностями */
}

.spinner {
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-left-color: #0a4475; /* Сиенго цвет */
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1023px) {
  h4,
  .h4 {
    font-size: 22px;
    margin: 0 0 20px 0;
  }
}

h4,
.h4 {
  font-size: 28px;
  line-height: 1.1;
  font-weight: 800;
  padding: 0;
  margin: 0 0 20px 0;
  letter-spacing: -0.03em;
}

.notificationPanel {
  display: flex;
  position: fixed;
  background: #fff;
  width: 85%;
  max-width: 350px;
  height: 100%;
  z-index: 100;
  top: 0;
  right: 0;
  overflow: auto;
  transition: 0.7s;
  box-shadow: -8px 0px 20px rgba(0, 0, 0, 0.05);
  flex-direction: column;
  justify-content: flex-start;
}

.notificationPanel.open {
  transform: translateX(-100%); /* Move the panel into view */
}

.notificationPanel.close {
  transform: translateX(0); /* Move the panel off-screen */
}

.slidePanelTitle {
  width: 100%;
  padding: 15px 40px 0px 15px;
  z-index: 200;
  background: #fff;
}

.small3 {
  font-size: 14px;
}
.small2 {
  font-size: 16px;
}

@media (max-width: 575px) {
  .notification {
    width: 100%;
    max-width: none;
  }
}
@media (max-width: 1023px) {
  .small2 {
    font-size: 14px;
  }
}

div {
  display: block;
  unicode-bidi: isolate;
}
@media (max-width: 1023px) {
  body {
    font-size: 16px;
  }
}
body {
  font-family: 'Manrope', sans-serif;
  font-size: 18px;
  line-height: 1.3;
  padding: 0;
  margin: 0;
  font-weight: 400;
  color: #012140;
}

.closeSlidePanel {
  display: inline-block;
  position: absolute;
  border-radius: 40px;
  background: #fff;
  top: 8px;
  right: 5px;
  padding: 5px;
  color: #012140;
  font-weight: 300;
  text-align: center;
  font-size: 50px;
  line-height: 25px;
  cursor: pointer;
  opacity: 0.6;
}

.rowNotice {
  position: relative;
}
.borderRadius5 {
  border-radius: 5px;
}
.bgGray {
  background-color: #f9f9f9;
}
.bxShadowSmall {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}
.slidePanelContent {
  overflow: auto;
  padding: 15px;
  background: #fff;
  height: 100wh;
  margin: 0;
}

.rowNotice .hideNotice {
  position: absolute;
  font-size: 30px;
  line-height: 20px;
  top: 0px;
  right: 0px;
  color: #012140;
  cursor: pointer;
  opacity: 0.4;
  padding: 8px;
}

.normal {
  font-weight: normal;
  color: #0a4475;
  font-family: 'Manrope', sans-serif;
  line-height: 1.3;
}

.bold {
  font-weight: bold;
  color: #0a4475;
  font-family: 'Manrope', sans-serif;
  line-height: 1.3;
}

.normal:hover,
.bold:hover {
  color: #9a031a;
}

.small,
small {
  font-size: 16px;
}

.rowJusify {
  justify-content: space-around;
}
@media (max-width: 1023px) {
  .small,
  small {
    font-size: 14px;
  }
}

.calendar {
  position: relative;
}

.range {
  width: 290px;
  border-radius: 8px 8px 0 0;
  box-shadow:
    0 4px 20px rgba(0, 0, 0, 0.1),
    0 -1px 0 rgba(0, 0, 0, 0);
}

.range > div > button {
  width: auto;
  text-wrap: nowrap;
  border-radius: 4px;
}

.range > div {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.range > div:last-child {
  display: none;
}

.range > div > button > span {
  color: #012140;
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 7px;
}

.dateRange {
  width: 289px;
  border-radius: 0 0 8px 8px;
  box-shadow:
    0 4px 20px rgba(0, 0, 0, 0.1),
    0 -1px 0 rgba(0, 0, 0, 0);
}

.dateRange > div > span > span > select {
  font-size: 14px;
  font-weight: 800;
  padding: 10px 20px 10px 5px;
}

.dateRange > div > div {
  width: auto !important;
  font-size: 14px;
}

.dateRange > div:first-child {
  background-color: #fff;
}

.calendarInput {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  width: 30px;
  height: 30px;
  right: 0;
  top: 5px;
  background-size: 20px 20px;
  background-position: calc(100% - 10px) 47%;
  background-repeat: no-repeat;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjJweCIgaGVpZ2h0PSIyMnB4IiB2aWV3Qm94PSIwIDAgMjIgMjIiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIyIDIyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAxMjE0MCIgc3Ryb2tlLXdpZHRoPSIxLjciIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgZD0iTTE3LDNINQ0KCUMyLjc5MSwzLDEsNC43OTEsMSw3djEwYzAsMi4yMDksMS43OTEsNCw0LDRoMTJjMi4yMDksMCw0LTEuNzkxLDQtNFY3QzIxLDQuNzkxLDE5LjIwOSwzLDE3LDN6Ii8+DQo8cGF0aCBmaWxsPSJub25lIiBzdHJva2U9IiMwMTIxNDAiIHN0cm9rZS13aWR0aD0iMS43IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGQ9Ik03LDF2NCBNMTUsMXY0IE0xLDloMjAiDQoJLz4NCjwvc3ZnPg0K');
}

input[type='text'] select,
textarea {
  font-size: inherit;
}

input[type='text'],
input[type='text'] {
  font-family: 'Manrope', sans-serif;
  font-size: inherit;
  line-height: 1;
  height: 39px;
  padding: 6px 10px 6px 10px;
  color: #012140;
  background-color: #fff;
  display: block;
  width: 100%;
  margin: 0;
  border-top: 1px solid #ffffffa3;
  border-bottom: 1px solid #01214024;
  border-left: 1px solid #01214024;
  border-right: 1px solid #01214024;
  border-radius: 5px;
  box-shadow: inset 1px 3px 5px 0px #0000001c;
  max-width: 100% !important;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

input[type='text']:focus,
input[type='text']:active {
  border-color: #012140;
}

.btn {
  font-family: 'Manrope', sans-serif;
  background-color: #b60e27;
  color: #fff !important;
  margin: 0;
  text-align: center;
  font-size: 18px;
  line-height: 1.3;
  text-transform: uppercase;
  padding: 12px 30px;
  font-weight: 800;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid #b60e27;
  border-radius: 5px;
  user-select: none;
  position: relative;
}

.btn:hover {
  background: #9a031a;
  border-color: #9a031a;
  color: #fff !important;
  /*transform:scale(1.05);*/
}

.btnBlue:hover {
  background: #0a4475;
  border-color: #0a4475;
  color: #fff !important;
}

.btn:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 20%;
  height: 100%;
  width: 50%;
  opacity: 0;
  -webkit-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjIyLjY1cHgiIGhlaWdodD0iMjcwLjA1NnB4IiB2aWV3Qm94PSIwIDAgMjIyLjY1IDI3MC4wNTYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIyMi42NSAyNzAuMDU2Ig0KCSB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik05NC4yNCwyNjkuMjU1SDIuMzczbDEyNi42NDgtMTM0LjQ1M0w2Ny42MzksMC41MjdoOTEuODk3bDYxLjM2NiwxMzQuMjc1TDk0LjI0LDI2OS4yNTV6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
}

.btn:hover::before {
  opacity: 0.2;
  left: 44%;
}

.btn .icon {
  width: 18px;
  height: 18px;
  line-height: 1;
  margin-right: 8px;
  margin-top: -2px;
}

.btnWide {
  display: block;
  width: 100%;
}

.btnSmall {
  font-size: 16px !important;
  padding: 8px 15px !important;
  text-transform: none;
  margin: 0;
}

.btnSmall.btnIcon {
  width: 37px !important;
}

.btnBlueOutline .iconEdit {
  margin-top: -2px !important;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTNweCIgaGVpZ2h0PSIxM3B4IiB2aWV3Qm94PSIwIDAgMTMgMTMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDEzIDEzIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAxMjE0MCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBkPSJNOC45ODMsMi42MDVsMS40MTIsMS40MTFMOC45ODMsMi42MDV6DQoJIE05Ljg5MSwxLjM2Mkw2LjA3Myw1LjE4QzUuODc2LDUuMzc3LDUuNzQxLDUuNjI4LDUuNjg3LDUuOTAxTDUuMzM0LDcuNjY3bDEuNzY1LTAuMzUzYzAuMjczLTAuMDU1LDAuNTI0LTAuMTg5LDAuNzIxLTAuMzg2DQoJbDMuODE4LTMuODE4YzAuMTE1LTAuMTE1LDAuMjA2LTAuMjUxLDAuMjY4LTAuNDAxQzExLjk2OSwyLjU1OSwxMiwyLjM5OCwxMiwyLjIzNmMwLTAuMTYyLTAuMDMyLTAuMzIzLTAuMDk0LTAuNDczDQoJYy0wLjA2Mi0wLjE1LTAuMTUzLTAuMjg2LTAuMjY4LTAuNDAxYy0wLjExNS0wLjExNS0wLjI1MS0wLjIwNi0wLjQwMS0wLjI2OEMxMS4wODgsMS4wMzIsMTAuOTI3LDEsMTAuNzY1LDENCgljLTAuMTYyLDAtMC4zMjMsMC4wMzItMC40NzMsMC4wOTRDMTAuMTQyLDEuMTU2LDEwLjAwNiwxLjI0Nyw5Ljg5MSwxLjM2Mkw5Ljg5MSwxLjM2MnoiLz4NCjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAxMjE0MCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBkPSJNMTAuNjY3LDl2MmMwLDAuMzU0LTAuMTQxLDAuNjkzLTAuMzkxLDAuOTQzDQoJYy0wLjI1LDAuMjUtMC41ODksMC4zOTEtMC45NDMsMC4zOTFIMmMtMC4zNTQsMC0wLjY5My0wLjE0MS0wLjk0My0wLjM5MWMtMC4yNS0wLjI1LTAuMzkxLTAuNTg5LTAuMzkxLTAuOTQzVjMuNjY3DQoJYzAtMC4zNTQsMC4xNC0wLjY5MywwLjM5MS0wLjk0M0MxLjMwOCwyLjQ3NCwxLjY0NywyLjMzMywyLDIuMzMzaDIiLz4NCjwvc3ZnPg0K');
}

.btnBlueOutline {
  background: none;
  color: #0a4475 !important;
  border: 1px solid #0a4475;
}

.btnBlueOutline:hover {
  background: #0a4475;
  border-color: #0a4475;
  color: #fff !important;
}

.iconRotate {
  transform: rotate(180deg);
}

.iconRotateInput {
  transform: rotate(180deg);
  right: 30px !important;
}

@media (max-width: 1023px) {
  .btn {
    font-size: 16px;
    padding: 12px 30px;
  }

  .btnSmall {
    font-size: 14px !important;
    padding: 8px 13px !important;
  }
}

.icon {
  display: inline-block;
  vertical-align: middle;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
}

.iconArrowBlue {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTRweCIgaGVpZ2h0PSI4cHgiIHZpZXdCb3g9IjAgMCAxNCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxNCA4IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiMwQTQ0NzUiIGQ9Ik0wLjYzNiwwLjI5M0MwLjgyNCwwLjEwNSwxLjA3OCwwLDEuMzQzLDBTMS44NjIsMC4xMDUsMi4wNSwwLjI5M0w3LDUuMjQzbDQuOTUtNC45NQ0KCWMwLjE4OS0wLjE4MiwwLjQ0MS0wLjI4MywwLjcwMy0wLjI4MWMwLjI2MiwwLjAwMiwwLjUxMywwLjEwNywwLjY5OCwwLjI5M3MwLjI5MSwwLjQzNiwwLjI5MywwLjY5OHMtMC4wOTksMC41MTUtMC4yODEsMC43MDMNCglMNy43MDcsNy4zNjRDNy41MTksNy41NTEsNy4yNjUsNy42NTcsNyw3LjY1N1M2LjQ4MSw3LjU1Miw2LjI5Myw3LjM2NEwwLjYzNiwxLjcwN0MwLjQ0OSwxLjUxOSwwLjM0MywxLjI2NSwwLjM0MywxDQoJUzAuNDQ5LDAuNDgsMC42MzYsMC4yOTN6Ii8+DQo8L3N2Zz4NCg==');
}

.calendarInput {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjJweCIgaGVpZ2h0PSIyMnB4IiB2aWV3Qm94PSIwIDAgMjIgMjIiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIyIDIyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAxMjE0MCIgc3Ryb2tlLXdpZHRoPSIxLjciIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgZD0iTTE3LDNINQ0KCUMyLjc5MSwzLDEsNC43OTEsMSw3djEwYzAsMi4yMDksMS43OTEsNCw0LDRoMTJjMi4yMDksMCw0LTEuNzkxLDQtNFY3QzIxLDQuNzkxLDE5LjIwOSwzLDE3LDN6Ii8+DQo8cGF0aCBmaWxsPSJub25lIiBzdHJva2U9IiMwMTIxNDAiIHN0cm9rZS13aWR0aD0iMS43IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGQ9Ik03LDF2NCBNMTUsMXY0IE0xLDloMjAiDQoJLz4NCjwvc3ZnPg0K');
}

.inputCalendar {
  cursor: pointer;
  position: relative;
  width: 100%;
}

.iconArrowBlueInput {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 15px;
  width: 30px;
  height: 30px;
  right: 25px;
  top: 5px;
}

.btn:hover .iconArrowBlue {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjYzNjA0OCAwLjI5Mjc4N0MwLjgyMzU3NiAwLjEwNTMxNiAxLjA3Nzg4IDAgMS4zNDMwNSAwQzEuNjA4MjEgMCAxLjg2MjUyIDAuMTA1MzE2IDIuMDUwMDUgMC4yOTI3ODdMNy4wMDAwNSA1LjI0Mjc5TDExLjk1IDAuMjkyNzg3QzEyLjEzODcgMC4xMTA2MjkgMTIuMzkxMyAwLjAwOTgzMzcyIDEyLjY1MzUgMC4wMTIxMTIxQzEyLjkxNTYgMC4wMTQzOTA2IDEzLjE2NjUgMC4xMTk1NiAxMy4zNTE5IDAuMzA0OTY4QzEzLjUzNzMgMC40OTAzNzYgMTMuNjQyNCAwLjc0MTE4OCAxMy42NDQ3IDEuMDAzMzhDMTMuNjQ3IDEuMjY1NTggMTMuNTQ2MiAxLjUxODE4IDEzLjM2NCAxLjcwNjc5TDcuNzA3MDUgNy4zNjM3OUM3LjUxOTUyIDcuNTUxMjYgNy4yNjUyMSA3LjY1NjU3IDcuMDAwMDUgNy42NTY1N0M2LjczNDg4IDcuNjU2NTcgNi40ODA1OCA3LjU1MTI2IDYuMjkzMDUgNy4zNjM3OUwwLjYzNjA0OCAxLjcwNjc5QzAuNDQ4NTc3IDEuNTE5MjYgMC4zNDMyNjIgMS4yNjQ5NSAwLjM0MzI2MiAwLjk5OTc4NkMwLjM0MzI2MiAwLjczNDYyMiAwLjQ0ODU3NyAwLjQ4MDMxNCAwLjYzNjA0OCAwLjI5Mjc4N1oiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=');
}

.dateRangeMobile {
  display: none;
}

.isMobileMargin {
  display: none;
}

@media (max-width: 1223px) {
  .filterBtn {
    width: 24%;
    min-width: 90px;
    margin-top: 2px;
  }

  .accountWideContent {
    display: block;
    width: 100%;
    margin: 0px !important;
  }

  .accountWideContentContainer {
    padding: 20px 15px !;
  }
}

@media (max-width: 1082px) {
  .dateRange {
    width: 269px;
  }

  .range {
    width: 270px;
  }
}

@media (max-width: 992px) {
  .flexInput {
    display: flex;
    justify-content: space-between;
    margin: 7px;
  }

  .dateInput {
    width: 100% !important;
    cursor: pointer;
  }

  .isMobileMargin {
    display: block;
    height: 70px;
  }

  .range {
    width: 100%;
    min-width: 290px;
    padding: 5px 0;
  }

  .dateRange {
    width: 100%;
    min-width: 290px;
  }
}

@media (max-width: 790px) {
  .range {
    width: 100%;
    min-width: 275px;
    padding: 5px 0;
  }

  .dateRange {
    width: 100%;
    min-width: 275px;
  }
}

@media (max-width: 420px) {
  .range {
    width: 100%;
  }

  .dateRange {
    width: 100%;
  }

  .filterBtn {
    margin-left: 1px;
  }

  .dateInput {
    width: 100% !important;
    cursor: pointer;
    font-size: 14px !important;
  }

  .calendarInput {
    background-size: 15px 15px;
  }

  .iconArrowBlueInput {
    right: 18px;
    top: 5px;
  }

  .iconRotateInput {
    right: 22px !important;
  }
}

@media (max-width: 320px) {
  .dateInput {
    font-size: 13px !important;
  }

  .iconArrowBlueInput {
    display: none;
  }
}

@media (max-width: 767px) {
  .dateRangeMobile {
    width: 100%;
  }

  .dateRangeMobile {
    display: block;
    position: absolute;
    z-index: 15;
  }

  .datePicker {
    display: none !important;
  }

  .accountRightCol {
    margin-left: 7px;
    padding-right: 7px !important;
  }
}

.bgWhite {
  background: #fff;
}

.bgWhite ul li:before {
  background-color: #0a4475;
}

.border10 {
  border-radius: 10px;
}

.bxShadow {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.05);
}

.small2 {
  font-size: 14px;
}

.docItem {
  position: relative;
  overflow: hidden;
}

.docItem > .row {
  margin-left: -5px;
  margin-right: -5px;
}

.docItem > .row > div {
  padding-left: 5px;
  padding-right: 5px;
  align-content: center;
}

.docItem .docClient {
  padding-right: 10px !important;
}

.docItem .docClient .client {
  min-height: 100%;
}

.docItem .docCar {
  padding-right: 10px !important;
}

.docItem .docMoney {
  width: 82px;
}

.docItem .docKm {
  min-width: 65px;
}

.docItem .docStatus {
  white-space: nowrap;
}

@media (max-width: 1279px) {
  .docItem .docCar:before {
    content: ' ';
    display: block;
    position: absolute;
    bottom: -2px;
    left: -1000%;
    height: 1px;
    width: 2000%;
    border-bottom: 1px solid #01214024;
  }
}

@media (max-width: 1023px) {
  .docItem .docClient:before {
    content: ' ';
    display: block;
    position: absolute;
    bottom: -2px;
    left: -1000%;
    height: 1px;
    width: 2000%;
    border-bottom: 1px solid #01214024;
  }

  .dropdown > .btnIcon.btnSmall.btnDropdown {
    padding: 5px 30px 5px 6px !important;
  }

  .dropdown > .btnDropdown:after {
    top: calc(50% - 7px);
    right: 8px;
    width: 15px;
    height: 15px;
  }
}

.small3 {
  font-size: 90%;
}

.colorLightGray {
  color: #acacb7 !important;
}

.borderRadius5 {
  border-radius: 5px;
}

.bgRed {
  background-color: #b60e27;
  color: #fff;
}

.bgTurquoise {
  background-color: #0fbaba;
  color: #fff;
}

.bgDarkBlue {
  background-color: #012140;
  color: #fff;
}

.bgBlue {
  background-color: #0a4475;
  color: #fff;
}

.colorRed {
  color: #b60e27 !important;
}

.colorTurquoise {
  color: #0fbaba !important;
}

.nowrap {
  white-space: nowrap;
}

.docItem > .row > div {
  padding-left: 5px;
  padding-right: 5px;
  align-content: center;
}

.docItem > .row {
  margin-left: -5px;
  margin-right: -5px;
}

.docItem > .row > div {
  padding-left: 5px;
  padding-right: 5px;
  align-content: center;
}

.accountRightCol {
  justify-content: space-around;
}
@media (max-width: 1460px) {
  .accountRightCol {
    padding-left: 65px !important;
  }
}

@media (max-width: 1199px) {
  .accountRightCol {
    padding-left: 0 !important;
  }
}

.btnBlueOutline {
  background: none;
  color: #0a4475 !important;
  border: 1px solid #0a4475;
}

.btnSmall2 {
  font-size: 14px !important;
  padding: 7px 13px !important;
  text-transform: none;
  width: 34px !important;
}

.btnIcon {
  border-color: transparent !important;
  width: 47px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.btnSmall.btnIcon {
  width: 37px !important;
}

.btnIcon .icon {
  margin-right: 0;
  margin-top: -2px;
}

.tooltip {
  position: relative;
}

.tooltipText {
  position: absolute;
  max-width: 173px;
  width: max-content;
  white-space: normal;
  right: 35px;
  top: 5px;
  background: #0f6dba;
  border-radius: 5px;
  color: #fff;
  padding: 5px 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  pointer-events: none;
  transition: 0.3s;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-align: center;
  opacity: 0;
  visibility: hidden;
}

.tooltip:hover .tooltipText {
  opacity: 1;
  visibility: visible;
}

.btn .icon {
  width: 18px;
  height: 18px;
  line-height: 1;
  margin-right: 0px;
  margin-top: -2px;
}

.icon {
  display: inline-block;
  vertical-align: middle;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
}
.iconDownload,
.btn.btnTrans:hover .iconDownload {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMiAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcgNi4yNUw1IDYuMjVDMi43OTA4NiA2LjI1IDEgOC4wNDA4NiAxIDEwLjI1TDEgMTUuMjVDMSAxNy40NTkxIDIuNzkwODYgMTkuMjUgNSAxOS4yNUwxNyAxOS4yNUMxOS4yMDkxIDE5LjI1IDIxIDE3LjQ1OTEgMjEgMTUuMjVMMjEgMTAuMjVDMjEgOC4wNDA4NiAxOS4yMDkxIDYuMjUgMTcgNi4yNUwxNSA2LjI1IiBzdHJva2U9IiMwMDI1NTIiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPHBhdGggZD0iTTggMTEuMjVMMTAuMjkyOSAxMy41NDI5QzEwLjY4MzQgMTMuOTMzNCAxMS4zMTY2IDEzLjkzMzQgMTEuNzA3MSAxMy41NDI5TDE0IDExLjI1IiBzdHJva2U9IiMwMDI1NTIiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPHBhdGggZD0iTTExIDEzLjI1TDExIDEuMjUiIHN0cm9rZT0iIzAwMjU1MiIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K');
}

.btn:hover .iconDownload {
  filter: contrast(0%) brightness(300%) !important;
}
.iconDownload:hover {
  filter: contrast(0%) brightness(300%) !important;
}

.accountContainer {
  position: relative;
  z-index: 1;
  padding: 100px 0 40px 0px;
}

.accountWideContent {
  display: block;
  width: calc(100% - 160px);
  vertical-align: top;
  margin-left: 160px;
  position: relative;
  z-index: 5;
}

.accountWideContent:before {
  content: ' ';
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  background: #f5f7f8;
  width: 100%;
  height: 100%;
}

.accountWideContentContainer {
  padding: 0 50px;
  position: relative;
  z-index: 5;
  max-width: 1600px;
  margin: 0 auto;
}

.radioTabs {
  justify-content: center;
}

.radioTabs label {
  display: block;
  padding: 0 0px 6px 0px;
  margin: 0 10px 0 10px;
  line-height: 1;
  text-align: center;
  font-weight: bold;
  color: #012140;
  cursor: pointer;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  border-bottom: 2px solid transparent;
}
.radioTabs label:before,
.radioTabs label:after {
  display: none !important;
}
.radioTabs label:hover {
  color: #0a4475;
  border-bottom: 2px solid #0a4475;
}
.radioTabs > *:first-child label {
  margin-left: 0;
}
.radioTabs > *:last-child label {
  margin-right: 0;
}

.radioTabs input[type='radio']:checked + label {
  color: #b60e27;
  border-bottom: 2px solid #b60e27;
}
.btnBlueOutline {
  background: none;
  color: #0a4475 !important;
  border: 1px solid #0a4475;
}

.btnBlueOutline:hover {
  background: #0a4475;
  border-color: #0a4475;
  color: #fff !important;
}

@media (max-width: 1023px) {
  .accountWideContent {
    display: block;
    width: 100%;
    margin: 0px !important;
  }

  .accountWideContentContainer {
    padding: 20px 15px;
  }

  .accountContainer {
    padding: 45px 0 0 0px;
  }
  .fontBig {
    font-size: 20px;
  }
  .small,
  small {
    font-size: 14px;
  }
}

@media (max-width: 1140px) {
  .accountWideContentContainer {
    padding: 20px 15px !important;
  }
}

@media (max-width: 1023px) {
  .accountWideContent {
    display: block;
    width: 100%;
    margin: 0px !important;
  }

  .accountWideContentContainer {
    padding: 20px 15px;
  }

  .accountContainer {
    padding: 45px 0 0 0px;
  }

  .btn {
    font-size: 16px;
    padding: 12px 30px;
  }

  .btnSmall {
    font-size: 14px;
    padding: 8px 13px;
  }
}

@media (max-width: 1279px) {
  .blockWhite {
    padding: 40px 60px;
  }
}

@media (max-width: 1023px) {
  .btnIcon {
    border-color: transparent !important;
    width: 47px;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .blockWhite {
    padding: 30px 40px;
  }

  .btn {
    font-size: 16px;
    padding: 12px 30px;
  }
}

@media (max-width: 767px) {
  .blockWhite {
    padding: 20px 20px;
  }
}

.dropdown {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.dropdownContent {
  position: absolute;
  z-index: 888;
  top: calc(100% + 5px);
  left: 0;
  display: none;
  border-radius: 5px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 4px 10px rgb(0 0 0 / 30%);
  min-width: 100%;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  user-select: none;
}

.dropdownSelect > * {
  padding: 8px 15px;
  cursor: pointer;
  display: block;
  border-bottom: 1px solid #01214024;
}

.dropdownSelect > *:hover {
  color: #b60e27;
}

.dropdownSelect > *:last-child {
  border: none;
}

.dropdownContentActive {
  display: block;
}

/* BTN */

.btn {
  font-family: 'Manrope', sans-serif;
  background-color: #b60e27;
  color: #fff !important;
  margin: 0;
  text-align: center;
  font-size: 18px;
  line-height: 1.3;
  text-transform: uppercase;
  padding: 12px 30px;
  font-weight: 800;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid #b60e27;
  border-radius: 5px;
  user-select: none;
  position: relative;
}

.btn:hover {
  background: #9a031a;
  border-color: #9a031a;
  color: #fff !important;
  /*transform:scale(1.05);*/
}

.btnBlue:hover {
  background: #0a4475;
  border-color: #0a4475;
  color: #fff !important;
}

.btn:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 20%;
  height: 100%;
  width: 50%;
  opacity: 0;
  -webkit-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjIyLjY1cHgiIGhlaWdodD0iMjcwLjA1NnB4IiB2aWV3Qm94PSIwIDAgMjIyLjY1IDI3MC4wNTYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIyMi42NSAyNzAuMDU2Ig0KCSB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik05NC4yNCwyNjkuMjU1SDIuMzczbDEyNi42NDgtMTM0LjQ1M0w2Ny42MzksMC41MjdoOTEuODk3bDYxLjM2NiwxMzQuMjc1TDk0LjI0LDI2OS4yNTV6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
}

.btn:hover::before {
  opacity: 0.2;
  left: 44%;
}

.btnBlue {
  background-color: #0a4475;
  border: 1px solid #0a4475;
}

.btnSmall {
  font-size: 16px !important;
  padding: 8px 15px !important;
  text-transform: none;
  margin: 0;
}

.btnDropdown {
  padding-right: 55px !important;
  padding-left: 15px !important;
  display: block;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
}

.btnInput {
  font-weight: 400;
  text-transform: none;
  color: #012140 !important;
  background-color: #fff;
  border-top: 1px solid #ffffffa3;
  border-bottom: 1px solid #01214024;
  border-left: 1px solid #01214024;
  border-right: 1px solid #01214024;
  box-shadow: inset 1px 3px 5px 0px #0000001c;
  max-width: 100% !important;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.btnDropdown:after {
  content: ' ';
  position: absolute;
  top: calc(50% - 9px);
  right: 15px;
  display: block;
  width: 18px;
  height: 18px;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTRweCIgaGVpZ2h0PSI4cHgiIHZpZXdCb3g9IjAgMCAxNCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAxNCA4IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiMwQTQ0NzUiIGQ9Ik0wLjYzNiwwLjI5M0MwLjgyNCwwLjEwNSwxLjA3OCwwLDEuMzQzLDBTMS44NjIsMC4xMDUsMi4wNSwwLjI5M0w3LDUuMjQzbDQuOTUtNC45NQ0KCWMwLjE4OS0wLjE4MiwwLjQ0MS0wLjI4MywwLjcwMy0wLjI4MWMwLjI2MiwwLjAwMiwwLjUxMywwLjEwNywwLjY5OCwwLjI5M3MwLjI5MSwwLjQzNiwwLjI5MywwLjY5OHMtMC4wOTksMC41MTUtMC4yODEsMC43MDMNCglMNy43MDcsNy4zNjRDNy41MTksNy41NTEsNy4yNjUsNy42NTcsNyw3LjY1N1M2LjQ4MSw3LjU1Miw2LjI5Myw3LjM2NEwwLjYzNiwxLjcwN0MwLjQ0OSwxLjUxOSwwLjM0MywxLjI2NSwwLjM0MywxDQoJUzAuNDQ5LDAuNDgsMC42MzYsMC4yOTN6Ii8+DQo8L3N2Zz4NCg==');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.btnDropdownActive:after {
  transform: rotate(180deg);
}

.btnBlue:after {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE0IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjYzNjA0OCAwLjI5Mjc4N0MwLjgyMzU3NiAwLjEwNTMxNiAxLjA3Nzg4IDAgMS4zNDMwNSAwQzEuNjA4MjEgMCAxLjg2MjUyIDAuMTA1MzE2IDIuMDUwMDUgMC4yOTI3ODdMNy4wMDAwNSA1LjI0Mjc5TDExLjk1IDAuMjkyNzg3QzEyLjEzODcgMC4xMTA2MjkgMTIuMzkxMyAwLjAwOTgzMzcyIDEyLjY1MzUgMC4wMTIxMTIxQzEyLjkxNTYgMC4wMTQzOTA2IDEzLjE2NjUgMC4xMTk1NiAxMy4zNTE5IDAuMzA0OTY4QzEzLjUzNzMgMC40OTAzNzYgMTMuNjQyNCAwLjc0MTE4OCAxMy42NDQ3IDEuMDAzMzhDMTMuNjQ3IDEuMjY1NTggMTMuNTQ2MiAxLjUxODE4IDEzLjM2NCAxLjcwNjc5TDcuNzA3MDUgNy4zNjM3OUM3LjUxOTUyIDcuNTUxMjYgNy4yNjUyMSA3LjY1NjU3IDcuMDAwMDUgNy42NTY1N0M2LjczNDg4IDcuNjU2NTcgNi40ODA1OCA3LjU1MTI2IDYuMjkzMDUgNy4zNjM3OUwwLjYzNjA0OCAxLjcwNjc5QzAuNDQ4NTc3IDEuNTE5MjYgMC4zNDMyNjIgMS4yNjQ5NSAwLjM0MzI2MiAwLjk5OTc4NkMwLjM0MzI2MiAwLjczNDYyMiAwLjQ0ODU3NyAwLjQ4MDMxNCAwLjYzNjA0OCAwLjI5Mjc4N1oiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=');
}

.btnBlue:hover,
.btnLight-blue:hover,
.btnWhite:hover,
.btnBlue-outline:hover,
.btnLight-blue-outline:hover,
.btnWhite-outline:hover,
.btnInput:hover {
  background: #0a4475;
  border-color: #0a4475;
  color: #fff !important;
}

.opacity02 {
  opacity: 0.2;
}
@media (max-width: 1023px) {
  h2,
  .h2 {
    font-size: 34px;
    margin: 0 0 30px 0;
    letter-spacing: -0.03em;
  }
}

@media (max-width: 575px) {
  .client .clientLogo {
    flex: 0 0 40px;
    margin: 0 7px 0 0;
  }
  .client .clientLogo img {
    height: 25px;
  }
  .client .name {
    flex: 0 0 calc(100% - 40px);
  }

  h2,
  .h2 {
    font-size: 30px;
    margin: 0 0 25px 0;
    letter-spacing: -0.03em;
  }

  table.tableFilterPanel,
  table.tableFilterPanel tbody,
  table.tableFilterPanel tr,
  table.tableFilterPanel td {
    display: block;
    padding: 0;
  }
  table.tableFilterPanel td {
    padding-bottom: 15px;
  }
  table.tableFilterPanel td.filterCellStatus {
    width: 48%;
    display: inline-block;
    float: left;
  }
  table.tableFilterPanel td.filterCellAdd {
    width: 48%;
    display: inline-block;
    float: right;
  }

  table.tableFilterPanel {
    margin: 0 0 0px 0;
  }

  .menu a:not(.btn) {
    padding: 5px;
  }

  .tableFilterPanel input[type='date'] {
    font-size: 14px;
    height: 45px;
  }

  .filterDocs .tableFilterPanel {
    margin-bottom: 0 !important;
  }
  .filterDocs.opened {
    display: block;
  }

  .docItem .docMoney:before {
    content: ' ';
    display: block;
    position: absolute;
    bottom: -2px;
    left: -1000%;
    height: 1px;
    width: 2000%;
    border-bottom: 1px solid #01214024;
  }

  .docItem .docDate:before {
    content: ' ';
    display: block;
    position: absolute;
    bottom: -2px;
    left: -1000%;
    height: 1px;
    width: 2000%;
    border-bottom: 1px solid #01214024;
  }
}

.bgWhite {
  background: #fff;
}

.bgWhite ul li:before {
  background-color: #0a4475;
}

.border10 {
  border-radius: 10px;
}

.bxShadow {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.05);
}

.small2 {
  font-size: 14px;
}

.docItem {
  position: relative;
  overflow: hidden;
}

.docItem > .row {
  margin-left: -5px;
  margin-right: -5px;
}

.docItem > .row > div {
  padding-left: 5px;
  padding-right: 5px;
  align-content: center;
}

.docItem .docClient {
  padding-right: 10px !important;
}

.docItem .docClient .client {
  min-height: 100%;
}

.docItem .docCar {
  padding-right: 10px !important;
}

.docItem .docMoney {
  width: 82px;
}

.docItem .docKm {
  min-width: 65px;
}

.docItem .docStatus {
  white-space: nowrap;
}

@media (max-width: 1279px) {
  .docItem .docCar:before {
    content: ' ';
    display: block;
    position: absolute;
    bottom: -2px;
    left: -1000%;
    height: 1px;
    width: 2000%;
    border-bottom: 1px solid #01214024;
  }
}

@media (max-width: 1023px) {
  .docItem .docClient:before {
    content: ' ';
    display: block;
    position: absolute;
    bottom: -2px;
    left: -1000%;
    height: 1px;
    width: 2000%;
    border-bottom: 1px solid #01214024;
  }

  .dropdown > .btnIcon.btnSmall.btnDropdown {
    padding: 5px 30px 5px 6px !important;
  }
  .dropdown > .btnDropdown:after {
    top: calc(50% - 7px);
    right: 8px;
    width: 15px;
    height: 15px;
  }
}

.borderNone {
  border: none !important;
}

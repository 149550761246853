.bgDarkBlue {
  background-color: #012140;
  color: #fff;
}

.small2 {
  font-size: 14px;
}

@media (max-width: 1023px) {
  .footer {
    padding: 30px 0 30px 0;
  }
  .footer-left-col {
    text-align: center;
  }
  .footer-left-col p {
    text-align: center;
  }
  .footer-right-col {
    text-align: center;
  }
  .footer-menu {
    text-align: center;
  }
  .footer-menu .separator {
    display: none;
  }
  .footer-menu .btn {
    display: block;
    margin: 0 auto;
  }
  .footer .copyright {
    text-align: center;
    margin: 0px 40px 0 40px;
  }
  .small2 {
    font-size: 14px;
  }
}

.linkTop {
  position: absolute;
  bottom: 0px;
  right: 15px;
  transform: rotate(90deg);
  font-size: 50px;
  font-weight: 800;
  opacity: 0.6;
}
.linkTop span {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  display: inline-block;
  margin: -4px 0px 0px -12px;
  vertical-align: middle;
  transform: rotate(-90deg);
}

.linkTop:hover {
  opacity: 1;
}

.footer {
  color: #fff;
  padding: 30px 0;
  position: absolute;
  z-index: 10;
  left: 0;
  bottom: 0;
  width: 100%;
}
.footer .container {
  position: relative;
}
.footer .footer-left-col {
  text-align: left;
}
.footer .footer-right-col {
  text-align: right;
}
.footer .copyright {
  opacity: 0.4;
  margin: 0 60px 0 60px;
}
.footer a:not(.btn) {
  color: #fff;
}

.footer-menu a:not(.btn) {
  display: inline-block;
  vertical-align: middle;
  font-weight: 800;
  color: #fff;
}
.footer-menu .separator {
  display: inline-block;
  height: 30px;
  width: 2px;
  background: #fff;
  vertical-align: middle;
  margin: 0 20px 0px 20px;
}
.footer-menu .btn {
  margin-left: 20px;
}

.block .container {
  z-index: 10;
  position: relative;
}

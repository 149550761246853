.pageLoading {
  position: fixed;
  z-index: 999999999999999;
  background: rgb(255 255 255 / 88%);
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  text-align: center;
}

.loadingImg {
  position: absolute;
  width: 100%;
  top: calc(40% - 30px);
  left: 0;
  text-align: center;
}

.logoCirc {
  display: inline-block;
  vertical-align: middle;
  width: 60%;
  max-width: 200px;
  height: 100px;
  background-size: contain !important;
}

.loadingTxt {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  color: #0a4475;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
}

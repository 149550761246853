.accountContainer {
  position: relative;
  z-index: 1;
  padding: 100px 0 40px 0px;
}

.accountWideContent {
  display: block;
  width: calc(100% - 160px);
  vertical-align: top;
  margin-left: 160px;
  position: relative;
  z-index: 5;
}

.accountWideContent:before {
  content: ' ';
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  background: #f5f7f8;
  width: 100%;
  height: 100%;
}

.accountWideContentContainer {
  padding: 0 50px;
  position: relative;
  z-index: 5;
  max-width: 1600px;
  margin: 0 auto;
}

h3,
.h3 {
  font-size: 34px;
  line-height: 1.1;
  font-weight: 800;
  padding: 0;
  margin: 0 0 25px 0;
  letter-spacing: -0.03em;
}

.blockWhite {
  background: #fff;
  color: #012140;
  padding: 40px 80px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.loadPhoto {
  text-align: center;
}

.colorBlue {
  color: #0a4475 !important;
}

.loadPhotoInputWrapper {
  position: relative;
  cursor: pointer;
}

.loadPhotoInputWrapper span {
  display: block;
  position: relative;
  z-index: 1;
}

.loadPhotoInputWrapper input {
  position: absolute;
  z-index: 10;
  left: 0;
  bottom: 0;
  opacity: 0;
  width: 100%;
  height: 15%;
  cursor: pointer;
}

.profileAvatar {
  width: 120px;
  height: 120px;
  border-radius: 5px;
  border: 1px solid #dddddd;
  margin: 0 auto;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  object-fit: cover;
}

.small,
small {
  font-size: 16px;
}

.formLine {
  margin: 0 0 25px 0;
}

.formLine small {
  display: block;
  margin: 0 0 5px 0;
}

.btn {
  font-family: 'Manrope', sans-serif;
  background-color: #b60e27;
  color: #fff !important;
  /* margin */
  text-align: center;
  font-size: 18px;
  line-height: 1.3;
  text-transform: uppercase;
  padding: 12px 30px;
  font-weight: 800;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid #b60e27;
  border-radius: 5px;
  user-select: none;
  position: relative;
}

.btn:hover {
  background: #9a031a;
  border-color: #9a031a;
  color: #fff !important;
}

.btn {
  font-family: 'Manrope', sans-serif;
  background-color: #b60e27;
  color: #fff !important;
  text-align: center;
  font-size: 18px;
  line-height: 1.3;
  text-transform: uppercase;
  padding: 12px 30px;
  font-weight: 800;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid #b60e27;
  border-radius: 5px;
  user-select: none;
  position: relative;
}

.btn:hover {
  background: #9a031a;
  border-color: #9a031a;
  color: #fff !important;
  /*transform:scale(1.05);*/
}

.btn:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 20%;
  height: 100%;
  width: 50%;
  opacity: 0;
  -webkit-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjIyLjY1cHgiIGhlaWdodD0iMjcwLjA1NnB4IiB2aWV3Qm94PSIwIDAgMjIyLjY1IDI3MC4wNTYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIyMi42NSAyNzAuMDU2Ig0KCSB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik05NC4yNCwyNjkuMjU1SDIuMzczbDEyNi42NDgtMTM0LjQ1M0w2Ny42MzksMC41MjdoOTEuODk3bDYxLjM2NiwxMzQuMjc1TDk0LjI0LDI2OS4yNTV6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
}

.btn:hover::before {
  opacity: 0.2;
  left: 44%;
}

.btn .icon {
  width: 18px;
  height: 18px;
  line-height: 1;
  margin-right: 8px;
  margin-top: -2px;
}

.icon {
  display: inline-block;
  vertical-align: middle;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
}

.iconCheckWhite {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDYiIGhlaWdodD0iNDYiIHZpZXdCb3g9IjAgMCA0NiA0NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yMi45OTkzIDEuOTE2NzVDMTEuMzU1NiAxLjkxNjc1IDEuOTE2MDIgMTEuMzU2MyAxLjkxNjAyIDIzLjAwMDFDMS45MTYwMiAzNC42NDM4IDExLjM1NTYgNDQuMDgzNCAyMi45OTkzIDQ0LjA4MzRDMzQuNjQzMSA0NC4wODM0IDQ0LjA4MjcgMzQuNjQzOCA0NC4wODI3IDIzLjAwMDFDNDQuMDgyNyAxMS4zNTYzIDM0LjY0MzEgMS45MTY3NSAyMi45OTkzIDEuOTE2NzVaTTMyLjEzOCAxOS40MzUxQzMyLjMwNjMgMTkuMjQyNyAzMi40MzQ0IDE5LjAxODcgMzIuNTE0OCAxOC43NzYxQzMyLjU5NTIgMTguNTMzNSAzMi42MjYzIDE4LjI3NzIgMzIuNjA2MiAxOC4wMjI1QzMyLjU4NjEgMTcuNzY3NyAzMi41MTUzIDE3LjUxOTUgMzIuMzk3OSAxNy4yOTI1QzMyLjI4MDQgMTcuMDY1NSAzMi4xMTg4IDE2Ljg2NDMgMzEuOTIyNSAxNi43MDA3QzMxLjcyNjEgMTYuNTM3IDMxLjQ5OTEgMTYuNDE0MyAzMS4yNTQ2IDE2LjMzOThDMzEuMDEwMiAxNi4yNjUyIDMwLjc1MzMgMTYuMjQwMyAzMC40OTkgMTYuMjY2NUMzMC4yNDQ4IDE2LjI5MjcgMjkuOTk4NCAxNi4zNjk1IDI5Ljc3NDMgMTYuNDkyM0MyOS41NTAyIDE2LjYxNTEgMjkuMzUyOSAxNi43ODE1IDI5LjE5NCAxNi45ODE3TDIwLjk1MjMgMjYuODY5OEwxNi42ODc4IDIyLjYwMzNDMTYuMzI2MyAyMi4yNTQyIDE1Ljg0MjEgMjIuMDYxIDE1LjMzOTYgMjIuMDY1NEMxNC44MzcgMjIuMDY5NyAxNC4zNTYzIDIyLjI3MTMgMTQuMDAwOSAyMi42MjY3QzEzLjY0NTYgMjIuOTgyIDEzLjQ0NCAyMy40NjI4IDEzLjQzOTYgMjMuOTY1M0MxMy40MzUzIDI0LjQ2NzkgMTMuNjI4NSAyNC45NTIgMTMuOTc3NiAyNS4zMTM1TDE5LjcyNzYgMzEuMDYzNUMxOS45MTU5IDMxLjI1MTcgMjAuMTQxNCAzMS4zOTg2IDIwLjM4OTYgMzEuNDk0OUMyMC42Mzc5IDMxLjU5MTEgMjAuOTAzNSAzMS42MzQ2IDIxLjE2OTQgMzEuNjIyNUMyMS40MzU0IDMxLjYxMDQgMjEuNjk2IDMxLjU0MyAyMS45MzQ1IDMxLjQyNDdDMjIuMTczIDMxLjMwNjMgMjIuMzg0MiAzMS4xMzk2IDIyLjU1NDcgMzAuOTM1MUwzMi4xMzggMTkuNDM1MVoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=');
}

.btnSmall {
  font-size: 16px !important;
  padding: 8px 15px !important;
  text-transform: none;
  margin: 0;
}

.btnIcon {
  border-color: transparent !important;
  width: 47px;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.btnSmall.btnIcon {
  width: 37px !important;
}

.btnIcon .icon {
  margin-right: 0;
  margin-top: -2px;
}

.btnBlueOutline .iconEdit {
  margin-top: -2px !important;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMTNweCIgaGVpZ2h0PSIxM3B4IiB2aWV3Qm94PSIwIDAgMTMgMTMiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDEzIDEzIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAxMjE0MCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBkPSJNOC45ODMsMi42MDVsMS40MTIsMS40MTFMOC45ODMsMi42MDV6DQoJIE05Ljg5MSwxLjM2Mkw2LjA3Myw1LjE4QzUuODc2LDUuMzc3LDUuNzQxLDUuNjI4LDUuNjg3LDUuOTAxTDUuMzM0LDcuNjY3bDEuNzY1LTAuMzUzYzAuMjczLTAuMDU1LDAuNTI0LTAuMTg5LDAuNzIxLTAuMzg2DQoJbDMuODE4LTMuODE4YzAuMTE1LTAuMTE1LDAuMjA2LTAuMjUxLDAuMjY4LTAuNDAxQzExLjk2OSwyLjU1OSwxMiwyLjM5OCwxMiwyLjIzNmMwLTAuMTYyLTAuMDMyLTAuMzIzLTAuMDk0LTAuNDczDQoJYy0wLjA2Mi0wLjE1LTAuMTUzLTAuMjg2LTAuMjY4LTAuNDAxYy0wLjExNS0wLjExNS0wLjI1MS0wLjIwNi0wLjQwMS0wLjI2OEMxMS4wODgsMS4wMzIsMTAuOTI3LDEsMTAuNzY1LDENCgljLTAuMTYyLDAtMC4zMjMsMC4wMzItMC40NzMsMC4wOTRDMTAuMTQyLDEuMTU2LDEwLjAwNiwxLjI0Nyw5Ljg5MSwxLjM2Mkw5Ljg5MSwxLjM2MnoiLz4NCjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAxMjE0MCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBkPSJNMTAuNjY3LDl2MmMwLDAuMzU0LTAuMTQxLDAuNjkzLTAuMzkxLDAuOTQzDQoJYy0wLjI1LDAuMjUtMC41ODksMC4zOTEtMC45NDMsMC4zOTFIMmMtMC4zNTQsMC0wLjY5My0wLjE0MS0wLjk0My0wLjM5MWMtMC4yNS0wLjI1LTAuMzkxLTAuNTg5LTAuMzkxLTAuOTQzVjMuNjY3DQoJYzAtMC4zNTQsMC4xNC0wLjY5MywwLjM5MS0wLjk0M0MxLjMwOCwyLjQ3NCwxLjY0NywyLjMzMywyLDIuMzMzaDIiLz4NCjwvc3ZnPg0K');
}

.btnBlueOutline {
  background: none;
  color: #0a4475 !important;
  border: 1px solid #0a4475;
}

.btnBlueOutline:hover {
  background: #0a4475;
  border-color: #0a4475;
  color: #fff !important;
}

.btnOutline {
  background: none;
  color: #b60e27 !important;
  border: 1px solid #b60e27;
}

@media (max-width: 1023px) {
  .accountWideContent {
    display: block;
    width: 100%;
    margin: 0px !important;
  }

  .accountWideContentContainer {
    padding: 20px 15px;
  }

  .accountContainer {
    padding: 45px 0 0 0px;
  }

  .btn {
    font-size: 16px;
    padding: 12px 30px;
  }

  .btnSmall {
    font-size: 14px;
    padding: 8px 13px;
  }
}

@media (max-width: 724px) {
  .btn {
    width: 100%;
  }

  .blockWhite {
    padding: 40px;
  }
}

.btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.btn:disabled:before {
  opacity: 0.5;
  content: none;
  cursor: not-allowed;
}

.btn:disabled .icon {
  display: none;
  cursor: not-allowed;
}

.btn:disabled:hover {
  opacity: 0.5;
  cursor: not-allowed;
}

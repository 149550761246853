.win {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: auto;
  z-index: 100010;
  display: none;
  -webkit-transition: 0s;
  -moz-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
}

.win:before {
  content: ' ';
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.47);
}

.winContainer {
  text-align: center;
  padding: 0;
  margin-top: 0;
  display: table-cell;
  width: 100vw;
  height: 100vh;
  vertical-align: top;
  position: relative;
}

.winContent {
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
  position: relative;
  text-align: left;
  display: inline-block;
  filter: drop-shadow(0px 8px 32px rgba(0, 0, 0, 0.1));
  max-width: 700px;
  width: 90%;
  min-width: 320px;
  margin: 10vw 20px 50px 20px;
}

.winContent .close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 50px;
  line-height: 25px;
  cursor: pointer;
  z-index: 10;
  opacity: 0.6;
}

.close:hover {
  opacity: 1;
}

.winContentFulltext {
  padding: 50px;
  overflow: auto;
}

.winContentFulltext img {
  width: 100% !important;
  height: auto !important;
}

h3,
.h3 {
  padding-left: 30px;
  padding-right: 30px;
  font-size: 34px;
  line-height: 1.1;
  font-weight: 800;
  padding: 0;
  letter-spacing: -0.03em;
}

.small2 {
  font-size: 14px;
}

.isError {
  border: 1px solid red !important;
}

.small2 label {
  padding: 3px 0 0 30px;
}

.colorRed {
  color: #b60e27 !important;
}

.small,
small {
  font-size: 16px;
}

.small input[type='checkbox'] + label:after,
.small input[type='radio'] + label:after,
.btnSmall input[type='checkbox'] + label:after,
.btnSmall input[type='radio'] + label:after,
.small input[type='checkbox'] + label:before,
.small input[type='radio'] + label:before,
.btnSmall input[type='checkbox'] + label:before,
.btnSmall input[type='radio'] + label:before {
  top: 0px;
}

.small .radioTabs label {
  padding-bottom: 4px;
}

.small2 .radioTabs label,
.small3 .radioTabs label {
  padding-bottom: 2px;
}

.btn.btnLoadFile .small {
  font-size: 14px;
  line-height: 17px;
  font-weight: normal;
}

.radioTabs {
  justify-content: center;
}

.radioTabs label {
  display: block;
  padding: 0 0px 6px 0px;
  margin: 0 10px 0 10px;
  line-height: 1;
  text-align: center;
  font-weight: bold;
  color: #012140;
  cursor: pointer;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  border-bottom: 2px solid transparent;
}

.radioTabs label:before,
.radioTabs label:after {
  display: none !important;
}

.radioTabs label:hover {
  color: #0a4475;
  border-bottom: 2px solid #0a4475;
}

.small .radioTabs label {
  padding-bottom: 4px;
}

.radioTabs > *:first-child label {
  margin-left: 0;
}

.radioTabs > *:last-child label {
  margin-right: 0;
}

.radioTabs input[type='radio']:checked + label {
  color: #b60e27;
  border-bottom: 2px solid #b60e27;
}

/* Container 720 */
@media (max-width: 1023px) {
  .small,
  small {
    font-size: 14px;
  }

  .small2 {
    font-size: 14px;
  }

  .small input[type='checkbox'] + label:after,
  .small input[type='radio'] + label:after,
  .btn-small input[type='checkbox'] + label:after,
  .btn-small input[type='radio'] + label:after,
  .small input[type='checkbox'] + label:before,
  .small input[type='radio'] + label:before,
  .btn-small input[type='checkbox'] + label:before,
  .btn-small input[type='radio'] + label:before {
    top: -1px;
  }
}

/* BUTTONS */

.btn,
button {
  font-family: 'Manrope', sans-serif;
  background-color: #b60e27;
  color: #fff !important;
  text-align: center;
  font-size: 18px;
  line-height: 1.3;
  text-transform: uppercase;
  padding: 12px 30px;
  font-weight: 800;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid #b60e27;
  border-radius: 5px;
  user-select: none;
  position: relative;
}

.btn {
  font-family: 'Manrope', sans-serif;
  background-color: #b60e27;
  color: #fff !important;
  margin: 0;
  text-align: center;
  font-size: 18px;
  line-height: 1.3;
  text-transform: uppercase;
  padding: 12px 30px;
  font-weight: 800;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid #b60e27;
  border-radius: 5px;
  user-select: none;
  position: relative;
}

.btn:hover {
  background: #9a031a;
  border-color: #9a031a;
  color: #fff !important;
  /*transform:scale(1.05);*/
}

.btn:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 20%;
  height: 100%;
  width: 50%;
  opacity: 0;
  -webkit-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjIyLjY1cHgiIGhlaWdodD0iMjcwLjA1NnB4IiB2aWV3Qm94PSIwIDAgMjIyLjY1IDI3MC4wNTYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIyMi42NSAyNzAuMDU2Ig0KCSB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik05NC4yNCwyNjkuMjU1SDIuMzczbDEyNi42NDgtMTM0LjQ1M0w2Ny42MzksMC41MjdoOTEuODk3bDYxLjM2NiwxMzQuMjc1TDk0LjI0LDI2OS4yNTV6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
}

.btn:hover::before {
  opacity: 0.2;
  left: 44%;
}

.btnBlueOutline {
  background: none;
  color: #0a4475 !important;
  border: 1px solid #0a4475;
}

.btnBlueOutline:hover {
  background: #0a4475;
  border-color: #0a4475;
  color: #fff !important;
}

@media (max-width: 1279px) {
  .winContent {
    max-width: 600px;
  }

  .winContentFulltext {
    padding: 30px;
  }
}

@media (max-width: 1023px) {
  .small,
  small {
    font-size: 14px;
  }

  h3,
  .h3 {
    font-size: 26px;
    margin: 0 0 25px 0;
  }

  h4,
  .h4 {
    font-size: 22px;
    margin: 0 0 20px 0;
  }

  .btn {
    font-size: 16px;
    padding: 12px 30px;
  }

  .btnSmall {
    font-size: 14px;
    padding: 8px 13px;
  }
}

@media (max-width: 767px) {
  .winContent {
    margin: 10px 15px;
  }

  .winContentFulltext {
    padding: 20px 20px 30px 20px;
  }
}

@media (max-width: 575px) {
  .btn {
    width: 100%;
  }
  h3,
  .h3 {
    font-size: 24px;
    margin: 0 0 20px 0;
  }
}

.calendarInput {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjJweCIgaGVpZ2h0PSIyMnB4IiB2aWV3Qm94PSIwIDAgMjIgMjIiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIyIDIyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAxMjE0MCIgc3Ryb2tlLXdpZHRoPSIxLjciIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgZD0iTTE3LDNINQ0KCUMyLjc5MSwzLDEsNC43OTEsMSw3djEwYzAsMi4yMDksMS43OTEsNCw0LDRoMTJjMi4yMDksMCw0LTEuNzkxLDQtNFY3QzIxLDQuNzkxLDE5LjIwOSwzLDE3LDN6Ii8+DQo8cGF0aCBmaWxsPSJub25lIiBzdHJva2U9IiMwMTIxNDAiIHN0cm9rZS13aWR0aD0iMS43IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGQ9Ik03LDF2NCBNMTUsMXY0IE0xLDloMjAiDQoJLz4NCjwvc3ZnPg0K');
}

.calendarInput {
  background-size: 20px 20px;
  background-position: calc(100% - 10px) 47%;
  background-repeat: no-repeat;
}

.date {
  color: #012140 !important;
}

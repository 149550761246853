.container {
  position: relative;
  .calendarImage {
    top: auto;
    position: absolute;
    display: block;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjJweCIgaGVpZ2h0PSIyMnB4IiB2aWV3Qm94PSIwIDAgMjIgMjIiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIyIDIyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAxMjE0MCIgc3Ryb2tlLXdpZHRoPSIxLjciIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgZD0iTTE3LDNINQ0KCUMyLjc5MSwzLDEsNC43OTEsMSw3djEwYzAsMi4yMDksMS43OTEsNCw0LDRoMTJjMi4yMDksMCw0LTEuNzkxLDQtNFY3QzIxLDQuNzkxLDE5LjIwOSwzLDE3LDN6Ii8+DQo8cGF0aCBmaWxsPSJub25lIiBzdHJva2U9IiMwMTIxNDAiIHN0cm9rZS13aWR0aD0iMS43IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIGQ9Ik03LDF2NCBNMTUsMXY0IE0xLDloMjAiDQoJLz4NCjwvc3ZnPg0K');
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    top: 10px;
    right: 6px;
    background-size: 21px;
  }
}

@media (max-width: 1023px) {
  .container {
    .calendarImage {
      top: 9px;
    }
  }
}

.calendar input:focus {
  outline: none;
  border: none;
}
.calendar.addPosition > div {
  top: 40px !important;
  right: 0 !important;
  width: 100%;
}

.calendar td {
  align-content: center;
}

:global(.rdtPicker td.rdtActive),
:global(.rdtPicker td.rdtActive:hover) {
  background-color: #0a4475 !important;
  border-radius: 5px;
}

:global(.rdtDay),
:global(.dow) {
  font-size: 13px;
}

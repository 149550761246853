.accountContainer {
  position: relative;
  z-index: 1;
  padding: 100px 0 40px 0px;
}

.accountWideContent {
  display: block;
  width: calc(100% - 160px);
  vertical-align: top;
  margin-left: 160px;
  position: relative;
  z-index: 5;
}

.accountWideContent:before {
  content: ' ';
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  background: #f5f7f8;
  width: 100%;
  height: 100%;
}

.accountWideContentContainer {
  padding: 0 50px;
  position: relative;
  z-index: 5;
  max-width: 1600px;
  margin: 0 auto;
}

.accountRightCol {
  padding-left: 35px;
}

@media (max-width: 1023px) {
  .accountRightCol {
    padding-left: 25px;
  }
}

@media (max-width: 767px) {
  .accountRightCol {
    padding-left: 15px;
  }
}

@media (max-width: 1140px) {
  .accountWideContentContainer {
    padding: 20px 15px !important;
  }
}

@media (max-width: 1023px) {
  .accountWideContent {
    display: block;
    width: 100%;
    margin: 0px !important;
  }

  .accountContainer {
    padding: 45px 0 0 0px;
  }

  .btn {
    font-size: 16px;
    padding: 12px 30px;
  }

  .btnSmall {
    font-size: 14px;
    padding: 8px 13px;
  }
}

.win {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: auto;
  z-index: 10000;
  display: none;
  -webkit-transition: 0s;
  -moz-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
}
.win:before {
  content: ' ';
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.47);
}
.winContainer {
  text-align: center;
  padding: 0;
  margin-top: 0;
  display: table-cell;
  width: 100vw;
  height: 100vh;
  vertical-align: middle;
  position: relative;
}
.winContent {
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
  position: relative;
  text-align: left;
  display: inline-block;
  filter: drop-shadow(0px 8px 32px rgba(0, 0, 0, 0.1));
  max-width: 700px;
  min-width: 320px;
  margin: 20px 20px;
}

.winContentFulltext {
  padding: 50px;
  overflow: auto;
}

.winContentFulltext img {
  width: 100% !important;
  height: auto !important;
}

.winContentFulltext h3,
.winContentFulltext .h3 {
  padding-left: 30px;
  padding-right: 30px;
  font-size: 34px;
  line-height: 1.1;
  font-weight: 800;
  padding: 0;
  margin: 0 0 25px 0;
  letter-spacing: -0.03em;
}

.winContent:after {
  display: block;
  width: 100%;
  content: ' ';
  clear: both;
}
.winContent .close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 50px;
  line-height: 25px;
  cursor: pointer;
  z-index: 10;
  opacity: 0.6;
}
.close:hover {
  opacity: 1;
}

.btn,
button {
  font-family: 'Manrope', sans-serif;
  background-color: #b60e27;
  color: #fff !important;
  text-align: center;
  font-size: 18px;
  line-height: 1.3;
  text-transform: uppercase;
  padding: 12px 30px;
  font-weight: 800;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid #b60e27;
  border-radius: 5px;
  user-select: none;
  position: relative;
}

.btn {
  font-family: 'Manrope', sans-serif;
  background-color: #b60e27;
  color: #fff !important;
  margin: 0;
  text-align: center;
  font-size: 18px;
  line-height: 1.3;
  text-transform: uppercase;
  padding: 12px 30px;
  font-weight: 800;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid #b60e27;
  border-radius: 5px;
  user-select: none;
  position: relative;
}

.btn:hover {
  background: #9a031a;
  border-color: #9a031a;
  color: #fff !important;
  /*transform:scale(1.05);*/
}

.btn:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 20%;
  height: 100%;
  width: 50%;
  opacity: 0;
  -webkit-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMjIyLjY1cHgiIGhlaWdodD0iMjcwLjA1NnB4IiB2aWV3Qm94PSIwIDAgMjIyLjY1IDI3MC4wNTYiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIyMi42NSAyNzAuMDU2Ig0KCSB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik05NC4yNCwyNjkuMjU1SDIuMzczbDEyNi42NDgtMTM0LjQ1M0w2Ny42MzksMC41MjdoOTEuODk3bDYxLjM2NiwxMzQuMjc1TDk0LjI0LDI2OS4yNTV6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
}

.btn:hover::before {
  opacity: 0.2;
  left: 44%;
}

.btnBlueOutline {
  background: none;
  color: #0a4475 !important;
  border: 1px solid #0a4475;
}

.btnBlueOutline:hover {
  background: #0a4475;
  border-color: #0a4475;
  color: #fff !important;
}
.btnSmall {
  font-size: 16px;
  padding: 8px 15px;
  text-transform: none;
  margin: 0;
}
.btnWide {
  display: block;
  width: 100%;
}

@media (max-width: 1279px) {
  .winContent {
    max-width: 600px;
  }
  .winContentFulltext {
    padding: 30px;
  }
}

@media (max-width: 767px) {
  .winContent {
    margin: 10px 15px;
  }

  .winContentFulltext {
    padding: 20px 20px 30px 20px;
  }
}

.formLine {
  margin: 0 0 25px 0;
}
.formLine small {
  display: block;
  margin: 0 0 5px 0;
}

.input[type='email'] {
  font-family: 'Manrope', sans-serif;
  font-size: inherit;
  line-height: 1;
  height: 39px;
  padding: 6px 10px 6px 10px;
  color: #012140;
  background-color: #fff;
  display: block;
  width: 100%;
  margin: 0;
  border-top: 1px solid #ffffffa3;
  border-bottom: 1px solid #01214024;
  border-left: 1px solid #01214024;
  border-right: 1px solid #01214024;
  border-radius: 5px;
  box-shadow: inset 1px 3px 5px 0px #0000001c;
  max-width: 100% !important;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}

.input[type='email']:focus,
.input[type='email']:active {
  border-color: #012140;
}

.input[type='email']:focus,
.input[type='email']:active {
  border-color: #012140;
}

.p {
  margin: 0 0 22px 0;
}

@media (max-width: 1279px) {
  .winContent {
    max-width: 600px;
  }

  .winContentFulltext {
    padding: 30px;
  }

  .p {
    margin: 0px 0 22px 0;
  }
}

@media (max-width: 1279px) {
  .formLine {
    margin: 0 0 15px 0;
  }
  .blockWhite {
    padding: 40px 60px;
  }
  .input[type='email'] {
    font-size: inherit;
  }
  .btn {
    font-size: 16px;
    padding: 12px 30px;
  }

  .input[type='email'] {
    height: 36px;
    padding: 6px 10px 6px 10px;
  }
}

@media (max-width: 1023px) {
  h4,
  .h4 {
    font-size: 22px;
    margin: 0 0 20px 0;
  }
  .btn {
    font-size: 16px;
    padding: 12px 30px;
  }
  .btnSmall {
    font-size: 14px;
    padding: 8px 13px;
  }
}
